/**
 * This helper is created so we only have one instance of a consumer
 * every time we need a websocket connection.
 */
import { createConsumer } from "@rails/actioncable"

let consumer;

function getConsumer() {
    if (!consumer) {
        consumer = createConsumer()
    }

    return consumer
}

export default getConsumer
